import { entrypoints } from "./build/asset-manifest.json";
const baseUrl = "https://module.jmp.berlin/gallery/";
function addcss(cssFile) {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");

    linkElement.setAttribute("href", `${baseUrl}${cssFile}`);

    var head = document.getElementsByTagName("head")[0];
    head.appendChild(linkElement);
}

function addScript(jsFile) {
    var s = document.createElement("script");
    s.setAttribute("src", `${baseUrl}${jsFile}`);
    document.body.appendChild(s);
}

entrypoints.forEach((entrypoint) => (entrypoint.includes(".css") ? addcss(entrypoint) : addScript(entrypoint)));
